import React from 'react'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">BEP20 Token Development Company</h1>
            <p className="sub-heading">Develop BEP20 tokens on the BSC chain with our BEP20 token development services on the most advantageous Binance smart chain. </p>
            <p className="sub-small mb-4">As a leading BEP20 token development company, we create BEP20 tokens compatible and fully functional with multiple DeFi business operations like DEX, dApp, and DAO functionalities.</p>
          </div>
          <div className="quick text-center">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/bep20-token-development.webp" alt="BEP20 Token Development Banner" />
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Get Our Expert Guidance</a>
        </div>
      </section>
    )
  }
}

export default BannerSection